<template>
	<div class="page home_page">
		<div class="bannerbox animated">
			<el-carousel indicator-position="none" arrow="never" :autoplay="false" :height="screenHeight+'px'">
			    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
			       <div class="imgbox">
					   <!-- :poster="item.imageUrl" -->
					    <video v-show="item.videoUrl" :src="item.videoUrl" 
						class="video img100"
						id="video"
						ref="videoPlayer"
						style="object-fit: cover;"
						:loop = "true"
						:autoplay="true"
						muted
						playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video>
					    <img v-if="!item.videoUrl" class="img100" :src="item.imageUrl"/>
						<div class="titlebox animation_inUp">
						   <div class="text1 fontsize68" v-if="item.title">{{item.title}}</div>
						   <div class="text2 fontsize36" v-if="item.subtitle">{{item.subtitle}}</div>
						   <div v-if="item.redirectUrl&&item.redirectUrl.trim()" class="navicon" @click="handleSearche(item.redirectUrl)"><img class="img100" src="../assets/static/navto.png"/></div>
					    </div>
					</div>
			    </el-carousel-item>
			</el-carousel>
		</div>
		<!-- 内容1 -->
		<div class="home_con1">
			<div class="w100 relative" ref="animatedElements" v-for="(item,index) in dataList" :key="index">
				<div class="w1200 box1" :style="'height:'+screenHeight+'px'" id="ani_0" :ref="setRef" v-if="index==0">
					<div class="leftbox animation_inUp">
						<div class="text1 fontsize32 huanhang">{{item.title}}</div>
						<div class="text2 fontsize16 huanhang">{{item.contents}}</div>
					</div>
					<div class="rightbox">
						<div class="imgvideo animation_inRight">
							<!-- <video v-show="item.videoUrl" :src="item.videoUrl"
							class="video img100"
							id="video"
							ref="videoPlayer"
							style="object-fit: cover;"
							:loop = "true"
							:autoplay="true"
							muted
							playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video> -->
							<img class="img100 hoverbig" :src="item.imageUrl"/>
						</div>
						<div class="imgbox2 animation_inRight" v-if="item.imgUrl">
							<img class="img100 hoverbig" :src="item.imgUrl"/>
						</div>
					</div>
				</div>
				<div class="box2_imgbg" v-if="index==1 || index==3 || index==5">
					<img class="img100" src="../assets/static/image1718.png"/>
				</div>
				<div class="w1200 box2" id="ani_1" :ref="setRef" v-if="index==1 || index==3 || index==5">
					<div class="rightbox animation_inLeft">
						<div class="imgvideo">
							<!-- <video v-show="item.videoUrl" :src="item.videoUrl"
							class="video img100"
							id="video"
							ref="videoPlayer"
							style="object-fit: cover;"
							:loop = "true"
							:autoplay="true"
							muted
							playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video> -->
							<img class="img100 hoverbig" :src="item.imageUrl"/>
						</div>
					</div>
					<div class="leftbox animation_inUp">
						<div class="text1 fontsize32 huanhang">{{item.title}}</div>
						<div class="text2 fontsize16 huanhang">{{item.contents}}</div>
					</div>
				</div>
				<div class="w1200 box3" id="ani_2" :ref="setRef" v-if="index==2 || index==4 || index==6">
					<div class="leftbox animation_inUp">
						<div class="text1 fontsize32 huanhang">{{item.title}}</div>
						<div class="text2 fontsize16 huanhang">{{item.contents}}</div>
					</div>
					<div class="rightbox animation_inRight">
						<div class="imgvideo">
							<!-- <video v-show="item.videoUrl" :src="item.videoUrl"
							class="video img100"
							id="video"
							ref="videoPlayer"
							style="object-fit: cover;"
							:loop = "true"
							:autoplay="true"
							muted
							playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video> -->
							<img class="img100 hoverbig" :src="item.imageUrl"/>
						</div>
					</div>
				</div>
			</div>
			<!-- 图片集合 -->
			<div class="w100 relative">
				<div class="box5">
					<div class="imgbox" ref="bannerElements" v-for="(item,index) in bannerList2" :key="index">
						<img class="img100 hoverbig animation_fadeInLeft" :src="item.imageUrl"/>
					</div>
				</div>
			</div>
		</div>
		
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			screenHeight:1080,//默认
			bannerList: [],
			bannerList2: [],
			dataList: [],//内容
			boxesElements:[],
			ani_List:[],
		};
	},
	mounted: function() {
		this.screenHeight = window.innerHeight;
		//初始化
		this.initPage()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	activated() {
	    console.log('Component A activated');
		var _this = this
		this.$nextTick(() => {
			if (_this.bannerList.length>0&&_this.$refs.videoPlayer&&_this.$refs.videoPlayer[0].paused) {
				// _this.$refs.videoPlayer.paused
				//console.log(_this.$refs.videoPlayer)
				_this.$refs.videoPlayer[0].play()
			}
		});
	},
	methods: {
		//初始化
		async initPage(){
			var _this = this
			//轮播图和广告
			await this.getwheelPage(1,'content/ct-wheel-advert/indexHeader/indexTag')
			await this.getwheelPage(3,'content/ct-article-info/IndexInfo')
			await this.getwheelPage(2,'content/ct-wheel-advert/indexHeader/indexBottom')
			// this.dataList = [
			// 	{ image: 'path/to/image1.jpg', isVisible: false },
			// 	{ image: 'path/to/image2.jpg', isVisible: false },
			// 	{ image: 'path/to/image3.jpg', isVisible: false },
			// ]
			var observer = null
			this.$nextTick(() => {
				_this.ani_List =  _this.boxesElements.map(el => {
				    const rect = el.getBoundingClientRect();
				    return rect.top;
				});
				observer = new IntersectionObserver(entries => {
				    entries.forEach(entry => {
				        if (entry.isIntersecting) {
				            // 元素进入视窗
				            entry.target.classList.add('animated', 'fadeInUp');
				        } else {
				            // 元素离开视窗
				            // entry.target.classList.remove('animated', 'fadeInUp');
				        }
				    });
				});
				// 遍历需要被观察的元素
				_this.$refs.animatedElements.forEach((element) => {
				   observer.observe(element);
				});
				//底部轮播图
				// observer.observe(_this.$refs.bannerElements);
				_this.$refs.bannerElements.forEach((element) => {
				   observer.observe(element);
				});
			});
		},
		setRef(el) {
		    if (el) {
		        this.boxesElements.push(el);
		    }
		},
		//滚动到指定位置
		scrollToElement(index) {
			console.log("跳转回调",index)
			// 平滑滚动到元素位置
			window.scrollTo({
			  top: this.ani_List[index],
			  behavior: 'smooth'
			});
			// const box = this.$refs[elementId];
			// console.log(box)
			// if (box) {
			//     const rect = box.getBoundingClientRect();
			// 	console.log(rect)
			//     console.log(`Box top position: ${rect.top}`);
			// 	window.scrollTo({
			// 	    top:rect.top,
			// 	    behavior: 'smooth'
			// 	});
			// }
		},
		//播放视频音频
		playvideosore(){
			var _this = this
			if (_this.bannerList.length>0&&_this.$refs.videoPlayer&&_this.$refs.videoPlayer[0].muted) {
				//console.log(_this.$refs.videoPlayer)
				_this.$refs.videoPlayer[0].muted = false
			}
		},
		//跳转
		navigato(url,query) {
			this.$util.routerPath(url,query)
		},
		//文章
		handledetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		//获取轮播图
		getwheelPage(type,conurl) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 18
				params["type"] = type
				this.$http.post(conurl, params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						// imageUrl:require("../assets/static/banner1.png")
						if(records.length>0){
							if(type==1){
								_this.bannerList = records
							}else if(type==2){
								_this.bannerList2 = records
							}else if(type==3){
								records.forEach((item,index)=>{
									if (item.imgUrlList) {
										try {
											item["imgUrlList"] = JSON.parse(item.imgUrlList)
											item["imageUrl"] = item.imgUrlList[0]
										} catch (e) {
											item["imgUrlList"] = []
											item["imageUrl"] = ""
											// TODO handle the exception
										}
									}
								})
								_this.dataList = records
							}else{
								
							}
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		//跳转
		handleSearche(url){
			if(url&&url!='no'&&url!='No'&&url!='NO'){
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					const newUrl = url.split('?')[0]
					const params = this.parseString(url)
					this.$router.push({
						path: "/"+newUrl,
						query:params
					});
				}
			}else{
				console.log("无跳转路径")
				//this.$message.info("此模块暂未开放")
			}
		},
		//处理参数
		parseString(url){
		    const obj = {};
		    if (url&&url.split('?').length>1) {
		      const params = url.split('?')[1].split('&');
		      params.map(item => obj[item.split("=")[0]] = item.split("=")[1]);
		    }
		    console.log(obj)
			return obj
		},
		
	}
};
</script>
<style lang="scss" scoped>
	.hoverbig:hover{
	    transform: scale(1.01);
	    transition: ease all 0.3s;
	}
</style>
